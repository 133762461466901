import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { languageSelectionAnalytics as getLanguageSelectionAnalyticsFunctions } from '../../../../common/components/organisms/LanguageSelector/LanguageSelectionAnalytics';
import { MyQuestsLanguageSelection } from './MyQuestsLanguageSelection';
import { LanguageConfirmModal } from '../../components/LanguageConfirmModal';
import { usePlatformContext } from '../../../platformContext';
import { useMemo, useRef, useState } from 'react';
import { Dialog } from '@mui/material';
export const MyQuestsLanguageModal = (props) => {
    var _a;
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const { logAnalyticsEvent } = usePlatformContext();
    const languageSelectionAnalytics = useMemo(() => {
        return getLanguageSelectionAnalyticsFunctions('myQuests', logAnalyticsEvent);
    }, [logAnalyticsEvent]);
    // Cache the available languages so the language selection modal can do its closing fadeout transition instead of disappearing instantly
    // The prop can't be made non-nullable because that would mean the dialog would only render if it's available, meaning the opening fadein transition will not work
    const cachedBackendData = useRef();
    if (props.open && props.backendData && props.backendData !== 'loading') {
        cachedBackendData.current = props.backendData;
    }
    const langs = (_a = cachedBackendData.current) === null || _a === void 0 ? void 0 : _a.selectedLanguages;
    return (_jsx(Dialog, { open: props.open, fullWidth: true, maxWidth: "xl", scroll: "body", onClose: () => {
            props.close();
            languageSelectionAnalytics.onCancelled();
        }, sx: (theme) => ({
            '& .MuiPaper-root:not(.MuiAlert-root)': {
                border: `1px solid ${theme.palette.container.border.default}`,
                backgroundColor: theme.palette.container.fill.card1,
                backgroundImage: 'unset',
                borderRadius: 3,
            },
        }), children: cachedBackendData.current && (_jsxs(_Fragment, { children: [_jsx(MyQuestsLanguageSelection, { searchPlaceholderKey: 'pages.learn.overview.languageSelection.searchPlaceholder', titleKey: 'pages.myQuests.languageSelection.selectLanguages', collections: cachedBackendData.current.availableLanguages, initiallySelectedLanguages: langs !== null && langs !== void 0 ? langs : [], initialCourseKind: langs ? (langs.length > 0 ? 'practical' : 'conceptual') : undefined, onSearchUsed: languageSelectionAnalytics.onSearchUsed, onCategoryToggled: languageSelectionAnalytics.onCategoryToggled, onSearchReset: languageSelectionAnalytics.onSearchReset, onBack: {
                        labelKey: 'pages.learn.overview.languageSelection.cancel',
                        action: () => {
                            props.close();
                            languageSelectionAnalytics.onCancelled();
                        },
                    }, onSubmit: {
                        practicalLabelKey: 'pages.learn.overview.languageSelection.submit',
                        conceptualLabelKey: 'pages.learn.overview.languageSelection.submitConceptual',
                        action: (languages) => {
                            setSelectedLanguages(languages);
                            setConfirmationDialogOpen(true);
                        },
                    } }), _jsx(LanguageConfirmModal, { open: confirmationDialogOpen, onConfirm: () => {
                        props.saveSelectedLanguages(selectedLanguages);
                        setConfirmationDialogOpen(false);
                        props.close();
                        const kind = selectedLanguages.length === 0 ? 'conceptual' : 'practical';
                        languageSelectionAnalytics.onSubmit(kind, selectedLanguages);
                    }, onCancel: () => setConfirmationDialogOpen(false), languages: Object.values(cachedBackendData.current.availableLanguages)
                        .flat()
                        .filter((lang) => selectedLanguages.includes(lang.id)), languageConfirmLoading: false })] })) }));
};
