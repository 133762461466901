export const scrollbarStyling = (theme) => ({
    '@supports (scrollbar-width: auto) and (not selector(::-webkit-scrollbar))': {
        scrollbarColor: `${theme.palette.container.border.input} ${theme.palette.container.border.default}`,
        scrollbarWidth: 'thin',
    },
    '@supports selector(::-webkit-scrollbar)': {
        '::-webkit-scrollbar': {
            // This could conflict with existing platform scrollbar styling
            WebkitAppearance: 'none',
            background: theme.palette.container.border.default,
            borderRadius: 3,
            width: 6,
        },
        '::-webkit-scrollbar-thumb': {
            background: theme.palette.container.border.input,
            borderRadius: 3,
        },
    },
});
