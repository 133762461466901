var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Box, Stack, Typography } from '@mui/material';
import { WarningRounded } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { Divider } from '../../Divider';
export const LanguageConfirmModal = (props) => {
    const { onConfirm, onCancel, languages, languageConfirmLoading } = props, rest = __rest(props, ["onConfirm", "onCancel", "languages", "languageConfirmLoading"]);
    return (_jsxs(Dialog, Object.assign({ sx: (theme) => ({
            '& .MuiDialog-paper': {
                borderRadius: theme.shape.border.radius.md,
                border: `1px solid ${theme.palette.container.border.default}`,
                background: theme.palette.container.fill.card1,
            },
        }), onClose: onCancel }, rest, { children: [_jsxs(Stack, { sx: (theme) => ({
                    padding: theme.spacing(8, 9, 8, 9),
                    alignItems: 'center',
                    gap: 6,
                }), children: [_jsxs(Stack, { sx: {
                            alignItems: 'center',
                            gap: 4,
                        }, children: [_jsx(WarningRounded, { sx: (theme) => ({ color: theme.palette.clickable.default, fontSize: theme.typography.pxToRem(56) }) }), _jsx(Typography, { variant: "h3", children: _jsx(Trans, { i18nKey: "pages.learn.overview.languageSelection.confirmation.title" }) })] }), _jsx(Typography, { variant: "subtitle1", sx: (theme) => ({ color: theme.palette.text.body }), component: "span", children: _jsx(Trans, { i18nKey: "pages.learn.overview.languageSelection.confirmation.description" }) }), languages.length > 0 && (_jsx(Stack, { direction: "row", sx: {
                            gap: 2,
                            flexWrap: 'wrap',
                        }, children: languages
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((language) => (_jsxs(Stack, { direction: "row", sx: (theme) => ({
                                height: theme.typography.pxToRem(36),
                                padding: theme.spacing(1, 3),
                                alignItems: 'center',
                                gap: 2,
                                borderRadius: theme.shape.border.radius.sm,
                                border: `1px solid ${theme.palette.container.border.default}`,
                                background: theme.palette.container.fill.card2,
                            }), children: [_jsx(Box, { sx: (theme) => ({
                                        fontSize: theme.typography.pxToRem(24),
                                        color: theme.palette.clickable.default,
                                    }), className: `${language.iconClassName}` }), _jsx(Typography, { variant: "button", children: language.label })] }, language.id))) }))] }), _jsx(Divider, { sx: (theme) => ({ background: theme.palette.container.border.default }) }), _jsxs(Stack, { direction: "row", sx: (theme) => ({
                    padding: theme.spacing(7, 9, 8, 9),
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }), children: [_jsx(Button, { disabled: languageConfirmLoading, variant: "outlined", onClick: onCancel, children: _jsx(Trans, { i18nKey: "pages.learn.overview.languageSelection.confirmation.cancel" }) }), _jsx(Button, { disabled: languageConfirmLoading, variant: "contained", onClick: onConfirm, children: _jsx(Trans, { i18nKey: "pages.learn.overview.languageSelection.confirmation.submit" }) })] })] })));
};
