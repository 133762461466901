export const languageSelectionAnalytics = (scope, sendEvent) => ({
    onSearchUsed: (searchTerm) => {
        sendEvent({ event: 'Language Search Changed', scope: scope, searchTerm: searchTerm });
    },
    onCategoryToggled: (wasLanguageSelected, languageId) => {
        const event = wasLanguageSelected ? 'Language Unselected' : 'Language Selected';
        sendEvent({ event, scope, languageId });
    },
    onSearchReset: () => {
        sendEvent({ event: 'Language Search Reset', scope });
    },
    onSubmit: (kind, selectedLanguages = []) => {
        sendEvent({ event: 'Languages submitted', scope, selectedLanguages, kind });
    },
    onCancelled: () => {
        sendEvent({ event: 'Language Selection Cancelled', scope });
    },
});
