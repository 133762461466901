import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Stack, Typography, Divider } from '@mui/material';
import { useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { Button, XButton } from '@securecodewarrior/design-system-react/lib/wanda';
import { LanguageSelectionPills, PracticalLanguageSelection, } from '../../../../common/components/organisms/LanguageSelector/LanguageSelection';
import { OptionCard } from '../../../questsAdmin/components/molecules/OptionCard';
export const MyQuestsLanguageSelection = (props) => {
    var _a;
    const { t } = useTranslation();
    const initiallySelectedLanguages = new Set(props.initiallySelectedLanguages);
    const [selectedLangs, setSelectedLangs] = useState(initiallySelectedLanguages);
    const languageSelectionRef = useRef(null);
    const [courseKind, setCourseKind] = useState((_a = props.initialCourseKind) !== null && _a !== void 0 ? _a : null);
    const onLanguageToggled = (id) => {
        setSelectedLangs((curr) => {
            const wasSelected = curr.has(id);
            props.onCategoryToggled(wasSelected, id);
            if (wasSelected) {
                curr.delete(id);
            }
            else {
                curr.add(id);
                setCourseKind('practical'); // would probably be fine to always set this on language toggle
            }
            return new Set(curr.values());
        });
    };
    return (_jsxs(Stack, { ref: languageSelectionRef, borderRadius: 3, position: "relative", bgcolor: (theme) => theme.palette.container.fill.card1, py: 8, children: [_jsx(XButton, { "aria-label": t(props.onBack.labelKey), onClick: props.onBack.action, sx: {
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                } }), _jsxs(Stack, { px: 7, mb: 5, gap: 4, children: [_jsx(Typography, { variant: "h2", component: "h1", children: t(props.titleKey) }), !!props.subtitleKey && (_jsx(Typography, { sx: (theme) => ({ strong: { color: theme.palette.text.soft } }), children: _jsx(Trans, { i18nKey: props.subtitleKey }) }))] }), _jsxs(Stack, { gap: 5, px: 7, children: [_jsx(OptionCard, { title: t('pages.myQuests.languageSelection.conceptual.title'), description: t('pages.myQuests.languageSelection.conceptual.subTitle'), selected: courseKind === 'conceptual', type: "radio", onChange: () => {
                            setCourseKind('conceptual');
                            setSelectedLangs(new Set());
                        } }), _jsx(OptionCard, { title: t('pages.myQuests.languageSelection.practical.title'), description: t('pages.myQuests.languageSelection.practical.subTitle'), selected: courseKind === 'practical', type: "radio", onChange: () => {
                            setCourseKind('practical');
                        }, sx: { overflow: 'hidden' }, children: _jsx(PracticalLanguageSelection, { collections: props.collections, maxHeight: "40vh", onLanguageToggled: onLanguageToggled, onSearchReset: props.onSearchReset, onSearchUsed: props.onSearchUsed, searchPlaceholderKey: props.searchPlaceholderKey, selectedLangs: selectedLangs }) })] }), _jsx(Divider, { sx: { mt: 8 } }), _jsxs(Stack, { direction: { xs: 'column', sm: 'row' }, justifyContent: "space-between", gap: 2, px: 7, mt: 8, children: [_jsx(Box, { flexGrow: { xs: 1, sm: 0 }, children: _jsx(Button, { variant: "outlined", onClick: props.onBack.action, sx: { flexShrink: 0 }, fullWidth: true, children: t(props.onBack.labelKey) }) }), courseKind === 'practical' && (_jsx(_Fragment, { children: selectedLangs.size === 0 ? (_jsxs(Stack, { direction: "row", alignItems: "center", gap: 2, children: [_jsx(ErrorOutlineIcon, { fontSize: "large", sx: (theme) => ({
                                        color: theme.palette.clickable.nav.item.default,
                                    }) }), _jsx(Typography, { children: _jsx(Trans, { i18nKey: "components.organisms.languageSelection.selectBeforeProceed" }) })] })) : (_jsx(LanguageSelectionPills, { collections: props.collections, languageSelectionRef: languageSelectionRef, onLanguageToggled: onLanguageToggled, selectedLangs: selectedLangs, sx: {
                                display: { xs: 'none', lg: 'flex' },
                                justifyContent: 'center',
                            } })) })), _jsx(Box, { flexShrink: 0, flexGrow: { xs: 1, sm: 0 }, children: _jsx(Button, { variant: "contained", fullWidth: true, disabled: courseKind === null || (courseKind === 'practical' && selectedLangs.size === 0), onClick: () => props.onSubmit.action(courseKind === 'practical' ? [...selectedLangs.values()] : [], courseKind), children: courseKind === 'practical'
                                ? t(props.onSubmit.practicalLabelKey, { count: selectedLangs.size })
                                : t(props.onSubmit.conceptualLabelKey) }) })] }), _jsx(LanguageSelectionPills, { collections: props.collections, languageSelectionRef: languageSelectionRef, onLanguageToggled: onLanguageToggled, selectedLangs: selectedLangs, sx: {
                    display: { xs: 'flex', lg: 'none' },
                    justifyContent: 'center',
                    mt: 3,
                } })] }));
};
