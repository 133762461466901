import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { LearnIcon } from './components/LearnIcon';
import { LanguageSelection, } from '../../../common/components/organisms/LanguageSelector/LanguageSelection';
import { LanguageConfirmModal } from '../components/LanguageConfirmModal';
import { Trans } from 'react-i18next';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformContext } from '../../platformContext';
import { languageSelectionAnalytics } from '../../../common/components/organisms/LanguageSelector/LanguageSelectionAnalytics';
import { learnAnalytics } from './learn.analytics';
export const LearnPageViewEmpty = ({ availableLanguages, updateLanguageSelection, savedSelectedLanguages, }) => {
    const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
    const [languageConfirmOpen, setLanguageConfirmOpen] = useState(false);
    const [languageConfirmLoading, setLanguageConfirmLoading] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const theme = useTheme();
    const { logAnalyticsEvent } = usePlatformContext();
    const languageAnalytics = useMemo(() => languageSelectionAnalytics('learn', logAnalyticsEvent), [logAnalyticsEvent]);
    const analytics = useMemo(() => learnAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const onLanguageConfirmed = (languageIds) => {
        setLanguageConfirmLoading(true);
        updateLanguageSelection(languageIds).then(() => setLanguageConfirmLoading(false));
    };
    const onLanguagesSelected = (selection) => {
        setSelectedLanguages(selection);
        setLanguageConfirmOpen(true);
    };
    const hasCompletedLanguageSelection = !!savedSelectedLanguages;
    return (_jsxs(Stack, { sx: (theme) => ({
            padding: theme.spacing(17, 12),
            alignItems: 'center',
            justifyContent: 'center',
            flex: '1 0 0',
            gap: 5,
        }), children: [_jsx(LearnIcon, { width: theme.typography.pxToRem(216), height: theme.typography.pxToRem(152) }), _jsxs(Stack, { sx: {
                    alignItems: 'center',
                    gap: 2,
                    strong: {
                        color: theme.palette.text.soft,
                    },
                }, children: [_jsx(Typography, { variant: "h3", component: "h2", children: _jsx(Trans, { i18nKey: hasCompletedLanguageSelection
                                ? 'pages.learn.overview.emptyCourses.noProductionLanguageTitle'
                                : 'pages.learn.overview.emptyCourses.title' }) }), _jsx(Typography, { variant: "subtitle1", sx: (theme) => ({
                            color: theme.palette.text.body,
                            width: theme.spacing(96),
                            textAlign: 'center',
                        }), component: "span", children: _jsx(Trans, { i18nKey: hasCompletedLanguageSelection
                                ? 'pages.learn.overview.emptyCourses.noProductionLanguageDescription'
                                : 'pages.learn.overview.emptyCourses.description' }) }), hasCompletedLanguageSelection ? null : availableLanguages ? (_jsx(Button, { variant: "contained", sx: {
                            marginTop: 3,
                        }, onClick: () => {
                            analytics.onFirstLanguageSelection();
                            setLanguageDialogOpen(true);
                        }, children: _jsx(Trans, { i18nKey: "pages.learn.overview.emptyCourses.selectLanguages" }) })) : (_jsx(Skeleton, { height: "35px", width: "190px", variant: "rounded", sx: { marginTop: 3 } }))] }), availableLanguages && (_jsxs(Dialog, { open: languageDialogOpen, fullWidth: true, maxWidth: "xl", scroll: "body", onClose: () => {
                    setLanguageDialogOpen(false);
                    languageAnalytics.onCancelled();
                }, sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        backgroundColor: theme.palette.container.fill.card1,
                        backgroundImage: 'unset',
                        borderRadius: 3,
                    },
                }), children: [_jsx(LanguageSelection, { required: true, searchPlaceholderKey: 'pages.learn.overview.languageSelection.searchPlaceholder', subtitleKey: 'pages.learn.overview.languageSelection.description', titleKey: 'pages.learn.overview.languageSelection.title', collections: availableLanguages, initiallySelectedLanguages: [], onSearchUsed: languageAnalytics.onSearchUsed, onCategoryToggled: languageAnalytics.onCategoryToggled, onSearchReset: languageAnalytics.onSearchReset, onBack: {
                            labelKey: 'pages.learn.overview.languageSelection.cancel',
                            action: () => {
                                setLanguageDialogOpen(false);
                                languageAnalytics.onCancelled();
                            },
                        }, onSubmit: {
                            labelKey: 'pages.learn.overview.languageSelection.submit',
                            action: (selected) => {
                                onLanguagesSelected(selected);
                                languageAnalytics.onSubmit('practical', selected);
                            },
                        }, changesRequired: true }), _jsx(LanguageConfirmModal, { open: languageConfirmOpen, onConfirm: () => onLanguageConfirmed(selectedLanguages), onCancel: () => setLanguageConfirmOpen(false), languages: Object.values(availableLanguages)
                            .flat()
                            .filter((lang) => selectedLanguages.includes(lang.id)), languageConfirmLoading: languageConfirmLoading })] }))] }));
};
