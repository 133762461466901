import { debounce } from '@mui/material';
import { getCustomVulnerabilityObjectives, getDaysDelta, getMostCommonVulnerabilitiesSelection, includesPciDssObjective, } from '../utils';
const editablePlatformFields = [
    'name',
    'badge',
    'isMandatory',
    'objectives',
    'startDate',
    'endDate',
    'assignedTeamIds',
];
const editableLMSFields = ['name', 'badge', 'objectives'];
const getUpdatedQuestFields = (questBuffer, initialQuest) => {
    const updatedFields = [];
    if (questBuffer.kind === 'platform' && initialQuest.kind === 'platform') {
        editablePlatformFields.forEach((field) => {
            if (`${questBuffer[field]}` !== `${initialQuest[field]}`) {
                updatedFields.push(field);
            }
        });
    }
    else if (questBuffer.kind === 'lms' && initialQuest.kind === 'lms') {
        editableLMSFields.forEach((field) => {
            if (`${questBuffer[field]}` !== `${initialQuest[field]}`) {
                updatedFields.push(field);
            }
        });
    }
    return updatedFields;
};
export const questDetailsAnalytics = (sendEvent) => ({
    onCreateQuestPageOpenedWithQueryParameters: (queryParameters, kind) => {
        var _a, _b, _c, _d;
        sendEvent({
            event: 'Create Quest Page Opened With Query Parameters',
            scope: 'admin-quest-details',
            kind,
            includesName: 'name' in queryParameters ? !!queryParameters.name : false,
            origin: ('origin' in queryParameters && queryParameters.origin) || 'missing',
            assignmentSelection: ('assignmentSelection' in queryParameters && queryParameters.assignmentSelection) || 'missing',
            teamCount: ((_a = queryParameters.teamIds) === null || _a === void 0 ? void 0 : _a.length) || 0,
            customVulnerabilityCount: ((_b = queryParameters.customVulnerabilities) === null || _b === void 0 ? void 0 : _b.length) || 0,
            customVulnerabilities: (_d = (_c = queryParameters.customVulnerabilities) === null || _c === void 0 ? void 0 : _c.join(', ')) !== null && _d !== void 0 ? _d : '',
        });
    },
    onNewQuestCreated: (questInput) => {
        var _a;
        try {
            const baseEvent = {
                event: 'New Quest Created',
                scope: 'admin-quest-details',
                vulnerabilityCount: getCustomVulnerabilityObjectives(questInput.objectives).length,
                mostCommonVulnerabilitiesSelection: getMostCommonVulnerabilitiesSelection(questInput.objectives),
                pciDssSelected: includesPciDssObjective(questInput.objectives),
                initialStatus: 'active',
                includesBadge: !!questInput.badge,
                origin: questInput.origin,
            };
            if (questInput.kind === 'platform') {
                const startDateDeltaInDays = getDaysDelta(questInput.startDate, new Date());
                const endDateDeltaInDays = getDaysDelta(questInput.endDate, new Date());
                let initialStatus = 'active';
                if (questInput.endDate && questInput.endDate.getTime() < Date.now()) {
                    initialStatus = 'finished';
                }
                if (questInput.startDate && questInput.startDate.getTime() > Date.now()) {
                    initialStatus = 'scheduled';
                }
                sendEvent(Object.assign(Object.assign({}, baseEvent), { kind: 'platform', startDateDeltaInDays,
                    endDateDeltaInDays,
                    initialStatus, isMandatory: questInput.isMandatory, assignedTeamCount: ((_a = questInput.assignedTeamIds) === null || _a === void 0 ? void 0 : _a.length) || 0 }));
            }
            else {
                sendEvent(Object.assign(Object.assign({}, baseEvent), { kind: 'lms', isMandatory: false }));
            }
        }
        catch (error) {
            console.error('error in questDetailsAnalytics.onNewQuestCreated: ', error);
        }
    },
    onDraftCreated: (questInput) => {
        var _a, _b, _c, _d, _e, _f;
        try {
            const baseEvent = {
                event: 'New Draft Created',
                scope: 'admin-quest-details',
                vulnerabilityCount: getCustomVulnerabilityObjectives(questInput.objectives).length,
                mostCommonVulnerabilitiesSelection: getMostCommonVulnerabilitiesSelection(questInput.objectives),
                pciDssSelected: includesPciDssObjective(questInput.objectives),
                includesBadge: !!questInput.badge,
                isMandatory: questInput.isMandatory,
                nameLength: ((_a = questInput.name) === null || _a === void 0 ? void 0 : _a.length) || 0,
                assignedTeamCount: ((_b = questInput.assignedTeamIds) === null || _b === void 0 ? void 0 : _b.length) || 0,
                origin: questInput.origin,
            };
            if (questInput.kind === 'platform') {
                const startDate = ((_d = (_c = questInput.startDate) === null || _c === void 0 ? void 0 : _c.getTime) === null || _d === void 0 ? void 0 : _d.call(_c)) || 'invalid';
                const endDate = ((_f = (_e = questInput.endDate) === null || _e === void 0 ? void 0 : _e.getTime) === null || _f === void 0 ? void 0 : _f.call(_e)) || 'invalid';
                sendEvent(Object.assign(Object.assign({}, baseEvent), { kind: 'platform', startDate, endDate }));
            }
            else {
                sendEvent(Object.assign(Object.assign({}, baseEvent), { kind: 'lms', isMandatory: false }));
            }
        }
        catch (error) {
            console.error('error in questDetailsAnalytics.onDraftCreated: ', error);
        }
    },
    onBackLinkClicked: (questStatus, questBuffer, initialQuest) => {
        const unsavedFields = getUpdatedQuestFields(questBuffer, initialQuest);
        sendEvent({
            event: 'Back Link Clicked',
            scope: 'admin-quest-details',
            questStatus,
            unsavedFields: unsavedFields.join(', '),
        });
    },
    onMandatoryToggled: (questStatus, isMandatory) => {
        sendEvent({
            event: 'Mandatory Toggled',
            scope: 'admin-quest-details',
            questStatus,
            isMandatory,
        });
    },
    onBadgeUpdated: (questStatus, badge) => {
        sendEvent({
            event: 'Badge Updated',
            scope: 'admin-quest-details',
            questStatus,
            badgeSize: (badge === null || badge === void 0 ? void 0 : badge.length) || 0,
        });
    },
    onObjectiveVulnerabilitiesUpdated: (questStatus, vulnsBefore, vulnsAfter, trigger) => {
        sendEvent({
            event: 'Objective Vulnerabilities Updated',
            scope: 'admin-quest-details',
            trigger,
            questStatus,
            countBefore: vulnsBefore.length,
            countAfter: vulnsAfter.length,
            vulnsBefore: vulnsBefore.join(', '),
            vulnsAfter: vulnsAfter.join(', '),
        });
    },
    onObjectiveSecurityConceptsUpdated: (questStatus, conceptsBefore, conceptsAfter, trigger) => {
        sendEvent({
            event: 'Security Concepts Updated',
            scope: 'admin-quest-details',
            trigger,
            questStatus,
            countBefore: conceptsBefore.length,
            countAfter: conceptsAfter.length,
            conceptsBefore: conceptsBefore.join(', '),
            conceptsAfter: conceptsAfter.join(', '),
        });
    },
    onMostCommonVulnerabilitiesUpdated: (questStatus, selection) => {
        sendEvent({
            event: 'Most Common Vulnerabilities Updated',
            scope: 'admin-quest-details',
            questStatus,
            selection,
        });
    },
    onPciDssToggled: (questStatus, selected) => {
        sendEvent({
            event: 'PCI DSS Toggled',
            scope: 'admin-quest-details',
            questStatus,
            selected,
        });
    },
    onTeamAssignmentUpdated: (questStatus, assignedTeamIdsBefore, assignedTeamIdsAfter, companyTeamCount, trigger) => {
        sendEvent({
            event: 'Team Assignment Updated',
            scope: 'admin-quest-details',
            trigger,
            questStatus,
            countBefore: assignedTeamIdsBefore.length,
            countAfter: assignedTeamIdsAfter.length,
            companyTeamCount,
        });
    },
    onStartDateUpdated: (questStatus, startDate, endDate) => {
        var _a, _b;
        const startDateInMs = ((_a = startDate === null || startDate === void 0 ? void 0 : startDate.getTime) === null || _a === void 0 ? void 0 : _a.call(startDate)) || 'invalid';
        const endDateInMs = ((_b = endDate === null || endDate === void 0 ? void 0 : endDate.getTime) === null || _b === void 0 ? void 0 : _b.call(endDate)) || 'invalid';
        const questLengthDays = getDaysDelta(endDate, startDate, true);
        sendEvent({
            event: 'Start Date Updated',
            scope: 'admin-quest-details',
            questStatus,
            startDate: startDateInMs,
            endDate: endDateInMs,
            questLengthDays,
            startDateDeltaInDays: getDaysDelta(startDate, new Date()),
        });
    },
    onEndDateUpdated: (questStatus, startDate, endDate) => {
        var _a, _b;
        const startDateInMs = ((_a = startDate === null || startDate === void 0 ? void 0 : startDate.getTime) === null || _a === void 0 ? void 0 : _a.call(startDate)) || 'invalid';
        const endDateInMs = ((_b = endDate === null || endDate === void 0 ? void 0 : endDate.getTime) === null || _b === void 0 ? void 0 : _b.call(endDate)) || 'invalid';
        const questLengthDays = getDaysDelta(endDate, startDate, true);
        sendEvent({
            event: 'End Date Updated',
            scope: 'admin-quest-details',
            questStatus,
            endDate: endDateInMs,
            startDate: startDateInMs,
            questLengthDays,
            endDateDeltaInDays: getDaysDelta(endDate, new Date()),
        });
    },
    onNameUpdated: debounce((questStatus, name) => {
        sendEvent({
            event: 'Name Updated',
            scope: 'admin-quest-details',
            questStatus,
            nameLength: (name === null || name === void 0 ? void 0 : name.length) || 0,
        });
    }, 1200),
    onArchiveClicked: (questStatus) => {
        sendEvent({
            event: 'Archive Clicked',
            scope: 'admin-quest-details',
            questStatus,
        });
    },
    onArchiveDialogClosed: (questStatus, confirmed) => {
        sendEvent({
            event: 'Archive Dialog Closed',
            scope: 'admin-quest-details',
            questStatus,
            confirmed,
        });
    },
    onPublishQuestDialogOpened: (questStatus, scenario) => {
        sendEvent({
            event: 'Publish Dialog Opened',
            scope: 'admin-quest-details',
            questStatus,
            scenario,
        });
    },
    onPublishQuestDialogClosed: (questStatus, scenario, confirmed) => {
        sendEvent({
            event: 'Publish Dialog Closed',
            scope: 'admin-quest-details',
            questStatus,
            confirmed,
            scenario,
        });
    },
    onDeleteClicked: (questStatus) => {
        sendEvent({
            event: 'Delete Clicked',
            scope: 'admin-quest-details',
            questStatus,
        });
    },
    onDeleteDialogClosed: (questStatus, confirmed) => {
        sendEvent({
            event: 'Delete Dialog Closed',
            scope: 'admin-quest-details',
            questStatus,
            confirmed,
        });
    },
    onChangesSaved: (questStatus, questBuffer, initialQuest, updatedStatus) => {
        const updatedFields = getUpdatedQuestFields(questBuffer, initialQuest);
        sendEvent({
            event: 'Changes Saved',
            scope: 'admin-quest-details',
            questStatus,
            updatedFields: updatedFields.join(', '),
            updatedStatus,
        });
    },
    onVulnerabilityDialogOpened: (questStatus, vulnCountBefore) => {
        sendEvent({
            event: 'Vulnerability Dialog Opened',
            scope: 'admin-quest-details',
            questStatus,
            vulnCountBefore,
        });
    },
    onVulnerabilityDialogClosed: (questStatus) => {
        sendEvent({
            event: 'Vulnerability Dialog Closed',
            scope: 'admin-quest-details',
            questStatus,
            confirmed: false,
        });
    },
    onSecurityConceptDialogOpened: (questStatus, conceptCountBefore) => {
        sendEvent({
            event: 'Security Concept Dialog Opened',
            scope: 'admin-quest-details',
            questStatus,
            conceptCountBefore,
        });
    },
    onSecurityConceptDialogClosed: (questStatus) => {
        sendEvent({
            event: 'Security Concept Dialog Closed',
            scope: 'admin-quest-details',
            questStatus,
            confirmed: false,
        });
    },
    onPciDssDialogOpened: (questStatus) => {
        sendEvent({
            event: 'PCI DSS Dialog Opened',
            scope: 'admin-quest-details',
            questStatus,
        });
    },
    onPciDssDialogClosed: (questStatus) => {
        sendEvent({
            event: 'PCI DSS Dialog Closed',
            scope: 'admin-quest-details',
            questStatus,
        });
    },
    onTeamAssignmentDialogOpened: (questStatus, teamCountBefore, companyTeamCount) => {
        sendEvent({
            event: 'Team Assignment Dialog Opened',
            scope: 'admin-quest-details',
            questStatus,
            teamCountBefore,
            companyTeamCount,
        });
    },
    onTeamAssignmentyDialogClosed: (questStatus) => {
        sendEvent({
            event: 'Team Assignment Dialog Closed',
            scope: 'admin-quest-details',
            questStatus,
            confirmed: false,
        });
    },
    onTeamAssignmentDialogSelectionChange: (questStatus, companyTeamCount, visibleRowCount, selectionCountBefore, selectionCountAfter) => {
        sendEvent({
            event: 'Team Assignment Dialog Selection Change',
            scope: 'admin-quest-details',
            questStatus,
            companyTeamCount,
            visibleRowCount,
            selectionCountBefore,
            selectionCountAfter,
        });
    },
    onTeamAssignmentDialogFilterChange: (questStatus, companyTeamCount, visibleRowCount, searchTerms) => {
        sendEvent({
            event: 'Team Assignment Dialog Filter Change',
            scope: 'admin-quest-details',
            questStatus,
            companyTeamCount,
            visibleRowCount,
            searchTerms: searchTerms.join(', '),
        });
    },
    onTeamAssignmentDialogSortChange: (questStatus, companyTeamCount, visibleRowCount, sortModel) => {
        var _a, _b;
        sendEvent({
            event: 'Team Assignment Dialog Sort Change',
            scope: 'admin-quest-details',
            questStatus,
            companyTeamCount,
            visibleRowCount,
            sortColumn: (_a = sortModel[0]) === null || _a === void 0 ? void 0 : _a.field,
            sortDirection: (_b = sortModel[0]) === null || _b === void 0 ? void 0 : _b.sort,
        });
    },
    onVulnerabilitySearchUpdated: debounce((questStatus, search, groupedCategories, filteredCategorySelection, variant) => {
        try {
            const updatedCollection = filteredCategorySelection(groupedCategories, search);
            sendEvent({
                event: 'Vulnerability Search Updated',
                scope: 'admin-quest-details',
                questStatus,
                search,
                subcategoryResultCount: updatedCollection.reduce((acc, cat) => acc + cat.categories.length, 0),
                categoryResultCount: updatedCollection.length,
                variant,
            });
        }
        catch (error) {
            console.error('error in onVulnerabilitySearchUpdated: ', error);
        }
    }, 700),
    onVulnerabilityCategorySelected: (questStatus, categoryKey, search) => {
        sendEvent({
            event: 'Vulnerability Category Selected',
            scope: 'admin-quest-details',
            questStatus,
            categoryKey,
            search,
        });
    },
    onVulnerabilityCategoryUnselected: (questStatus, categoryKey, search) => {
        sendEvent({
            event: 'Vulnerability Category Unselected',
            scope: 'admin-quest-details',
            questStatus,
            categoryKey,
            search,
        });
    },
    onVulnerabilitySubcategorySelected: (questStatus, subcategoryKey, search, variant) => {
        sendEvent({
            event: 'Vulnerability Subcategory Selected',
            scope: 'admin-quest-details',
            questStatus,
            subcategoryKey,
            search,
            variant,
        });
    },
    onVulnerabilitySubcategoryUnselected: (questStatus, subcategoryKey, search, variant) => {
        sendEvent({
            event: 'Vulnerability Subcategory Unselected',
            scope: 'admin-quest-details',
            questStatus,
            subcategoryKey,
            search,
            variant,
        });
    },
    onDownloadedScormVersion: (questStatus, questId, scormVersion) => {
        sendEvent({
            event: 'Downloaded SCORM Package',
            scope: 'admin-quest-details',
            questStatus,
            questId,
            scormVersion,
        });
    },
});
